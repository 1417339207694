import React from 'react';
import './Sent.css';
import Navbar from '../../../Components/Navbar/Navbar';

function Sent() {
    return (
        <main className='Sent'>
            <Navbar />
            <div id='container' className='container'>
                
            </div>
        </main>
    );
}

export default Sent;
